import React from 'react'
import elFaroProduccionesLogo  from '../imgs/el-faro-producciones-logo.png'
import '../styles/layout/maintenance.scss'

const Maintenance = () => {
    return (
        <div className="maintenance d-flex align-items-center text-center justify-content-center">
            <div className="maintenance__message">
                <img className="maintenance__logo" src={ elFaroProduccionesLogo } alt="logo de el faro producciones"/>
                <p className="maintenance__text">Página temporalmente en mantenimiento</p>
            </div>
        </div>
    )
}

export default Maintenance;