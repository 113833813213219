import React from 'react';
import Maintenance from './components/maintenance'
import 'bootstrap/dist/css/bootstrap.css';
import '../src/styles/styles.scss'

function App() {
  return (
    <div className="App">
      <Maintenance/>
    </div>
  );
}

export default App;
